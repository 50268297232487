import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form"
import { useFormSubmissionTracking } from "../../analytics/useFormSubmissionTracking"
import { useFormLoadTracking } from "../../analytics/useFormLoadTracking"

let globalHubspotFormId = 0

const HubspotFormInner = props => {
  const { className, htmlFormId } = props
  const location = window.location.pathname.split("/")

  useHubspotForm({
    portalId: props.portalId,
    formId: props.formId,
    target: `#${props.htmlFormId}`,
    locale: location && location[1],

    translations: {
      en: {
        required: "Please complete this required field",
        submissionErrors: {
          MISSING_REQUIRED_FIELDS: "Please fill in all required fields!"
        }
      },
      fr: {
        required: "Veuillez remplir ce champ obligatoire",
        submissionErrors: {
          MISSING_REQUIRED_FIELDS: "Veuillez remplir les champ obligatoire."
        }
      },
      de: {
        required: "Bitte füllen Sie dieses Pflichtfeld aus",
        submissionErrors: {
          MISSING_REQUIRED_FIELDS: "Bitte füllen Sie die Pflichtfelder aus"
        }
      },
      it: {
        required: "Compila il campo richiesto",
        submissionErrors: {
          MISSING_REQUIRED_FIELDS: "Compila il campi richiesti"
        }
      },
      es: {
        required: "Rellene este campo obligatorio",
        submissionErrors: {
          MISSING_REQUIRED_FIELDS: "Rellene los campos obligatorios"
        }
      }
    }
  })

  return <div className={className} id={htmlFormId}></div>
}

HubspotFormInner.propTypes = {
  className: PropTypes.string,
  portalId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  htmlFormId: PropTypes.string.isRequired
}

export default function HubspotFormComponent(props) {
  const { onFormSubmitted, onFormSubmit, locale } = props

  const [htmlFormId, _setHtmlFormId] = useState(null)
  const htmlFormIdRef = useRef(htmlFormId)
  const { trackFormSubmission } = useFormSubmissionTracking(props)
  const { trackFormLoadStart, trackFormLoadEnd } = useFormLoadTracking()
  let formSubmitEvent = null

  const _trackFormSubmission = event => {
    const eventData = event.data.data

    trackFormSubmission({
      eventAction: props.eventAction,
      eventLabel: props.eventLabel,
      formData: eventData,
      formId: props.formId,
      locale
    })
  }

  const setHtmlFormId = value => {
    htmlFormIdRef.current = value
    _setHtmlFormId(value)
  }

  const formEventHandler = event => {
    // onFormSubmit event
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmit" &&
      event.data.id === props.formId
    ) {
      formSubmitEvent = () => {
        _trackFormSubmission(event)
      }
      if (onFormSubmit && typeof onFormSubmit === "function") {
        onFormSubmit(event)
      }
    }

    // onFormSubmitted event
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormSubmitted" &&
      event.data.id === props.formId
    ) {
      if (formSubmitEvent && typeof formSubmitEvent === "function") {
        formSubmitEvent()
      }

      // scroll to top of the element
      const htmlElement = document.getElementById(htmlFormIdRef.current)
      if (htmlElement) {
        htmlElement.scrollIntoView({ block: "center" })
      }

      if (onFormSubmitted && typeof onFormSubmitted === "function") {
        onFormSubmitted(event)
      }
    }

    // onFormReady event
    if (
      event.data.type === "hsFormCallback" &&
      event.data.eventName === "onFormReady" &&
      event.data.id === props.formId
    ) {
      trackFormLoadEnd(`${props.formId}_${_.get(props, "eventLabel", "")}`)
    }
  }

  useEffect(() => {
    setHtmlFormId(`hubspotFormComponent-${globalHubspotFormId++}`)
    trackFormLoadStart()

    window.addEventListener("message", formEventHandler)
    return () => {
      window.removeEventListener("message", formEventHandler)
    }
  }, [])

  if (!htmlFormId) {
    return null
  }

  return (
    <HubspotFormInner
      className={props.className}
      formId={props.formId}
      portalId={props.portalId}
      htmlFormId={htmlFormId}
    />
  )
}

HubspotFormComponent.propTypes = {
  className: PropTypes.string,
  portalId: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  eventAction: PropTypes.string,
  eventLabel: PropTypes.string,
  onFormSubmit: PropTypes.func,
  onFormSubmitted: PropTypes.func,
  locale: PropTypes.string.isRequired
}
