import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "react-bootstrap"
import _ from "lodash"
import YoutubeVideo from "../../../YoutubeVideo/YoutubeVideo"
import GradientBorder from "../../../GradientBorder/GradientBorder"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { getRenderingOptions } from "../../renderEmbeddedEntries"
import styles from "./IntroVideo.module.scss"

const IntroVideo = props => {
  const { locale, defaultLocale } = props.pageContext

  const body = _.get(props, "data.contentfulClient.introBody")
  const youtubeVideoId = _.get(
    props,
    "data.contentfulClient.youtubeVideoId",
    ""
  )
  const videoName = _.get(props, "data.contentfulClient.videoName", "")
  const previewImage = _.get(props, "data.contentfulClient.thumbnail", {})

  const renderContent = () => {
    if (!body) return null
    return (
      <Col xs={12} lg={youtubeVideoId ? 6 : 12}>
        <div className={styles.bodyWrap} id="header-video">
          {renderRichText(body, getRenderingOptions(locale, defaultLocale))}
        </div>
      </Col>
    )
  }

  const renderVideo = () => {
    if (!youtubeVideoId) return null

    return (
      <Col xs={12} lg={body ? 6 : 12}>
        <div className={styles.videoWrap}>
          <YoutubeVideo
            previewImage={previewImage}
            videoName={videoName}
            videoId={youtubeVideoId}
          />
        </div>
      </Col>
    )
  }

  return (
    <div className={styles.intro}>
      <Container className={styles.container}>
        <div className={styles.gradientBorderWrap}>
          <GradientBorder borderRadius={[40, 0, 40, 40]} />
        </div>
        <Row>
          <Col xs={12}>
            <div className={styles.contentCard}>
              <Row>
                {renderContent()}
                {renderVideo()}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
IntroVideo.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}
export default IntroVideo
