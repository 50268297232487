import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import styles from "./Intro.module.scss"
import { Container, Row, Col } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { isSVG } from "../../../../utilities/mediaUtils"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { getRenderingOptions } from "../../renderEmbeddedEntries"
const Intro = props => {
  const { locale, defaultLocale } = props.pageContext
  const media = _.get(props, "data.contentfulClient.introMedia")
  const body = _.get(props, "data.contentfulClient.introBody")

  const renderMedia = () => {
    if (!media) return null

    if (isSVG(media)) {
      return (
        <img
          src={media.file.url}
          alt={media.description || ""}
          className={styles.background}
        />
      )
    } else if (media.gatsbyImageData) {
      return (
        <GatsbyImage
          className={styles.background}
          image={media.gatsbyImageData}
          alt={media.description || ""}
        />
      )
    }
  }

  const renderContent = () => {
    return (
      <Container>
        <Row>
          <Col xs="12" lg="6" className={styles.bodyWrap}>
            <div className={styles.bodyCard}>
              {renderRichText(body, getRenderingOptions(locale, defaultLocale))}
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (!body) return null
  return (
    <div className={styles.intro}>
      {renderMedia()}
      {renderContent()}
    </div>
  )
}
Intro.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}
export default Intro
