import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { graphql } from "gatsby"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import { Helmet } from "react-helmet"
import getPageData from "../../analytics/getPageData"
import { renderTags } from "../../utilities/renderTags"
import Template from "../../components/Template/Template"
import CaseStudy from "../../components/CaseStudy/CaseStudy"
import { useScrollTracking } from "../../analytics/useScrollTracking"
import { usePageTracking } from "../../analytics/usePageTracking"
import { getHTMLLang } from "../../utilities/utilities"

export const query = graphql`
  query ($id: String!, $locale: String!) {
    contentfulClient(id: { eq: $id }, node_locale: { eq: $locale }) {
      id
      metaTitle
      metaDescription {
        metaDescription
      }
      node_locale
      name
      title
      slug
      pagePerimeter
      pagePublication
      pageFeature
      segmentPublishedAt: publishedDate(formatString: "yyyyMMDD")
      segmentUpdatedAt: updatedAt(formatString: "yyyyMMDD")
      template {
        ...templateFields
      }
      extraTags {
        ...extraTagFields
      }
      introMedia {
        ...assetFields
        gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: NONE)
      }
      introBody {
        raw
        references {
          __typename
          ... on ContentfulLink {
            ...linkFields
          }
        }
      }
      keyTakeaways {
        raw
        references {
          __typename
          ... on ContentfulTestimonial {
            contentful_id
            author
            detail
            profilePicture {
              description
              gatsbyImageData(
                layout: CONSTRAINED
                width: 60
                aspectRatio: 1
                resizingBehavior: FILL
                quality: 100
                placeholder: NONE
              )
            }
            testimonial {
              testimonial
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulLink {
            ...linkFields
          }
        }
      }
      keyTakeawaysFeatures {
        ...featureItemFields
      }
      companyLogo {
        ...assetFields
        gatsbyImageData(
          layout: CONSTRAINED
          width: 200
          quality: 100
          placeholder: NONE
        )
      }
      companyName
      activityArea
      companySize
      location
      useCase
      ctaLink {
        ...linkFields
      }
      customersHistory {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            contentful_id
            title
            description
            file {
              ...assetFileFields
            }
            gatsbyImageData(
              layout: CONSTRAINED
              width: 852
              quality: 100
              placeholder: NONE
            )
          }
          ... on ContentfulLink {
            ...linkFields
          }
          ... on ContentfulTestimonial {
            contentful_id
            author
            detail
            profilePicture {
              description
              gatsbyImageData(
                layout: CONSTRAINED
                width: 60
                aspectRatio: 1
                resizingBehavior: FILL
                quality: 100
                placeholder: NONE
              )
            }
            testimonial {
              testimonial
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      customersNeeds {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            contentful_id
            title
            description
            file {
              ...assetFileFields
            }
            gatsbyImageData(
              layout: CONSTRAINED
              width: 852
              quality: 100
              placeholder: NONE
            )
          }
          ... on ContentfulLink {
            ...linkFields
          }
        }
      }
      solution {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            contentful_id
            title
            description
            file {
              ...assetFileFields
            }
            gatsbyImageData(
              layout: CONSTRAINED
              width: 852
              quality: 100
              placeholder: NONE
            )
          }
          ... on ContentfulLink {
            ...linkFields
          }
          ... on ContentfulTestimonial {
            contentful_id
            author
            detail
            profilePicture {
              description
              gatsbyImageData(
                layout: CONSTRAINED
                width: 60
                aspectRatio: 1
                resizingBehavior: FILL
                quality: 100
                placeholder: NONE
              )
            }
            testimonial {
              testimonial
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      youtubeVideoId
      videoName
      thumbnail {
        ...assetFields
        gatsbyImageData(
          layout: CONSTRAINED
          width: 860
          height: 484
          resizingBehavior: FILL
          quality: 100
          placeholder: NONE
        )
      }
      solutionFooter {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            contentful_id
            title
            description
            file {
              ...assetFileFields
            }
            gatsbyImageData(
              layout: CONSTRAINED
              width: 852
              quality: 100
              placeholder: NONE
            )
          }
          ... on ContentfulLink {
            ...linkFields
          }
        }
      }
      conclusion {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            contentful_id
            title
            description
            file {
              ...assetFileFields
            }
            gatsbyImageData(
              layout: CONSTRAINED
              width: 852
              quality: 100
              placeholder: NONE
            )
          }
          ... on ContentfulLink {
            ...linkFields
          }
          ... on ContentfulFeatures {
            ...featuresFields
          }
        }
      }
      nextSteps {
        raw
      }
      knowMore {
        raw
        references {
          __typename
          ... on ContentfulHubspotForm {
            ...hubspotFormFields
          }
        }
      }
      otherCaseStudies {
        ...entryFields
        link {
          ...linkFields
        }
        headContent {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        resources {
          ...entryFields
          name
          title
          link {
            ...linkFields
          }
          activityArea
          location
          problems
          thumbnail {
            description
            gatsbyImageData(
              layout: CONSTRAINED
              width: 380
              height: 260
              resizingBehavior: FILL
              quality: 100
              placeholder: NONE
            )
          }
        }
      }
      videoOption
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const ClientCaseStudy = props => {
  useScrollTracking()
  usePageTracking()

  const {
    pageContext: { locale, defaultLocale, localeLinks },
    data: { contentfulClient }
  } = props

  // SEO Data
  const htmlLang = getHTMLLang(locale)
  const title = _.get(props, "data.contentfulClient.metaTitle", "")
  const metaDescription = _.get(
    props,
    "data.contentfulClient.metaDescription.metaDescription",
    ""
  )
  const pageUrl = `${props.data.site.siteMetadata.siteUrl}${_.get(
    props,
    "pageContext.pageUrl",
    ""
  )}`

  // Page Data
  const pageData = getPageData(
    locale,
    {
      pagePerimeter: "client",
      pagePublication: _.get(
        contentfulClient,
        "segmentPublishedAt",
        contentfulClient.segmentUpdatedAt
      ),
      pageFeature: _.get(contentfulClient, "pageFeature", "")
    },
    contentfulClient.slug,
    props.path
  )

  return (
    <>
      <Layout locale={locale} defaultLocale={defaultLocale}>
        <SEO
          description={metaDescription}
          lang={htmlLang}
          title={title}
          url={pageUrl}
        />
        <Helmet>
          <script type="text/javascript">{`${pageData}`}</script>
          {renderTags(locale, defaultLocale, contentfulClient.extraTags)}
          {contentfulClient.template &&
            contentfulClient.template.headTags &&
            renderTags(
              locale,
              defaultLocale,
              contentfulClient.template.headTags
            )}
        </Helmet>
        <Template
          {...contentfulClient.template}
          isMenuFixed={true}
          localeLinks={localeLinks}
          locale={locale}
          defaultLocale={defaultLocale}
        >
          <CaseStudy {...props}></CaseStudy>
        </Template>
      </Layout>
    </>
  )
}
ClientCaseStudy.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.object,
  pageContext: PropTypes.object
}
export default ClientCaseStudy
