import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "react-bootstrap"
import FeatureItemCard from "../FeatureItemCard/FeatureItemCard"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { getRenderingOptions } from "../../renderEmbeddedEntries"
import { translate, KEYS } from "../../translations"
import styles from "./KeyTakeaways.module.scss"

const KeyTakeaways = props => {
  const {
    data: {
      contentfulClient: { keyTakeaways, keyTakeawaysFeatures }
    },
    pageContext: { locale, defaultLocale }
  } = props

  const renderBody = () => {
    return (
      <Col xs="12" lg={keyTakeawaysFeatures ? 9 : 12} className="order-2">
        <div className={styles.bodyContainer}>
          {renderRichText(
            keyTakeaways,
            getRenderingOptions(locale, defaultLocale)
          )}
        </div>
      </Col>
    )
  }
  const renderFeatures = () => {
    if (!keyTakeawaysFeatures) return null
    return (
      <Col xs="12" lg="3" className="order-1 order-lg-3">
        <div className={styles.featuresContainer}>
          {keyTakeawaysFeatures.map(item => (
            <FeatureItemCard
              {...item}
              style="KeyTakeaways"
              key={item.id}
            ></FeatureItemCard>
          ))}
        </div>
      </Col>
    )
  }

  if (!keyTakeaways) return null
  return (
    <div className={styles.keyTakeaways} id="key_takeaway">
      <Container>
        <Row>
          <Col>
            <h2 className={styles.sectionTitle}>
              {translate(KEYS.SECTION_TITLE_KEY_TAKEAWAYS, locale)}
            </h2>
          </Col>
        </Row>
        <Row>
          {renderBody()}
          {renderFeatures()}
        </Row>
      </Container>
    </div>
  )
}

KeyTakeaways.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
}
export default KeyTakeaways
