import { useState } from "react"
import { trackEvent } from "./trackEvent"

export const useFormLoadTracking = () => {
  const [startTime, setStartTime] = useState(new Date())

  const trackFormLoadStart = () => {
    setStartTime(new Date())
  }

  const trackFormLoadEnd = formName => {
    const endTime = new Date()

    trackEvent(`formLoaded`, null, null, {
      category: "form",
      time: endTime - startTime,
      page_country: "{{country_code}}",
      page_perimeter: "{{page_perimeter}}",
      label: formName
    })
  }

  return { trackFormLoadStart, trackFormLoadEnd }
}
