import React from "react"
import PropTypes from "prop-types"
import YoutubeVideo from "../../../YoutubeVideo/YoutubeVideo"
import { getRenderingOptions } from "../../renderEmbeddedEntries"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styles from "./BodySection.module.scss"
const BodySection = props => {
  const {
    name,
    htmlId,
    title,
    content,
    hasBackground,
    locale,
    defaultLocale,
    footer,
    youtubeVideoId,
    previewImage,
    videoName
  } = props

  if (!content) return null
  const sectionClasses = [styles.section]

  if (name === "nextSteps") {
    sectionClasses.push(styles.nextSteps)
  }
  if (hasBackground) {
    sectionClasses.push(styles.hasBackground)
  }

  // Title
  const renderTitle = () => {
    if (!title) return null
    return <h2 className={styles.sectionTitle}>{title}</h2>
  }

  // Render video (Solution section)
  const renderVideo = () => {
    if (!youtubeVideoId) return null

    return (
      <div className={styles.videoWrap} id="solution-video">
        <YoutubeVideo
          previewImage={previewImage}
          videoName={videoName}
          videoId={youtubeVideoId}
        />
      </div>
    )
  }

  return (
    <div className={sectionClasses.join(" ")} id={htmlId}>
      {renderTitle()}
      <div className={styles.content}>
        {renderRichText(content, getRenderingOptions(locale, defaultLocale))}
        {renderVideo()}
        {footer &&
          renderRichText(footer, getRenderingOptions(locale, defaultLocale))}
      </div>
    </div>
  )
}
BodySection.defaultProps = {
  content: null,
  hasBackground: false,
  htmlId: ""
}
BodySection.propTypes = {
  name: PropTypes.string,
  htmlId: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.object,
  hasBackground: PropTypes.bool,
  youtubeVideoId: PropTypes.string,
  previewImage: PropTypes.object,
  videoName: PropTypes.string,
  footer: PropTypes.object,
  locale: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired
}
export default BodySection
