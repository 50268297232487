import React from "react"
import _ from "lodash"
import { BLOCKS } from "@contentful/rich-text-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { isSVG } from "../../utilities/mediaUtils"
import Link from "../Link/Link"
import Testimonial from "./Subcomponents/Testimonial/Testimonial"
import HubspotFormWrapper from "../HubspotFormWrapper/HubspotForm"
import FeatureItemCard from "./Subcomponents/FeatureItemCard/FeatureItemCard"
import styles from "./CaseStudy.module.scss"

const renderLink = (node, locale, defaultLocale) => {
  const props = {
    link: _.get(node, "data.target", null),
    locale,
    defaultLocale
  }
  return <Link {...props} />
}

const renderTestimonial = (node, locale, defaultLocale) => {
  const props = {
    author: _.get(node, "data.target.author", ""),
    detail: _.get(node, "data.target.detail", ""),
    thumb: _.get(node, "data.target.profilePicture", null),
    testimonial: _.get(node, "data.target.testimonial.testimonial", null),
    locale,
    defaultLocale
  }

  return <Testimonial {...props} />
}

const renderHubspotForm = (node, locale) => {
  const portalId = _.get(node, "data.target.portalId", "")
  const formId = _.get(node, "data.target.formId", "")
  const backgroundColor = _.get(node, "data.target.backgroundColor", "#f9f9fb")
  const htmlId = _.get(node, "data.target.contentful_id", "")
  const style = _.get(node, "data.target.style", "Default")
  const eventAction = _.get(node, "data.target.eventAction", "")
  const eventLabel = _.get(node, "data.target.eventLabel", "")

  if (portalId && formId) {
    return (
      <HubspotFormWrapper
        htmlId={htmlId}
        portalId={portalId}
        formId={formId}
        backgroundColor={backgroundColor}
        style={style}
        eventAction={eventAction}
        eventLabel={eventLabel}
        locale={locale}
      />
    )
  }
  return null
}

const renderFeatures = (node, locale) => {
  const featureItems = _.get(node, "data.target.items", [])
  const id = _.get(node, "data.target.htmlId", "")
  if (!featureItems.length) return null
  return (
    <div id={id} className={styles.featuresContainer}>
      {featureItems.map(item => (
        <FeatureItemCard {...item} key={item.id}></FeatureItemCard>
      ))}
    </div>
  )
}

// Make slug from video title
const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "")

// Render assets
const renderAsset = node => {
  const contentType = _.get(node, "data.target.file.contentType", "")
  const media = _.get(node, "data.target", null)
  if (contentType.startsWith("image")) {
    if (isSVG(media)) {
      return (
        <img
          src={media.file.url}
          alt={media.description || ""}
          className={styles.background}
        />
      )
    } else if (media?.gatsbyImageData) {
      return (
        <GatsbyImage
          image={media.gatsbyImageData}
          className={styles.contentImage}
          alt={media.description || ""}
        />
      )
    }
  } else if (contentType.startsWith("video")) {
    return (
      <video
        id={slugify(node.data.target.title)}
        className={styles.contentVideo}
        controls
        title={node.data.target.description}
      >
        <source src={node.data.target.file.url} type={contentType} />
      </video>
    )
  }
}

export const getRenderingOptions = (locale, defaultLocale) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return renderAsset(node)
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        return renderEmbeddedEntry(node, locale, defaultLocale)
      }
    }
  }
  return options
}

export const renderEmbeddedEntry = (node, locale, defaultLocale) => {
  const entryType = _.get(node, "data.target.__typename")

  switch (entryType) {
    case "ContentfulTestimonial":
      return renderTestimonial(node, locale, defaultLocale)
    case "ContentfulLink":
      return renderLink(node, locale, defaultLocale)
    case "ContentfulHubspotForm":
      return renderHubspotForm(node, locale)
    case "ContentfulFeatures":
      return renderFeatures(node, locale)
    default:
      break
  }
}
