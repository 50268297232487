import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { getRenderingOptions } from "../../renderEmbeddedEntries"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { translate, KEYS } from "../../translations"
import styles from "./KnowMore.module.scss"
const KnowMore = props => {
  const { locale, defaultLocale } = props.pageContext
  const knowMore = _.get(props, "data.contentfulClient.knowMore", null)

  if (!knowMore) return null
  return (
    <div className={styles.knowMore} id="know_more">
      <h2 className={styles.sectionTitle}>
        {translate(KEYS.SECTION_TITLE_KNOW_MORE, locale)}
      </h2>
      <div className={styles.content}>
        {renderRichText(knowMore, getRenderingOptions(locale, defaultLocale))}
      </div>
    </div>
  )
}
KnowMore.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}
export default KnowMore
