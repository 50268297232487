import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import styles from "./CaseStudy.module.scss"
import { Container, Row, Col } from "react-bootstrap"

import Intro from "./Subcomponents/Intro/Intro"
import IntroVideo from "./Subcomponents/IntroVideo/IntroVideo"
import KeyTakeaways from "./Subcomponents/KeyTakeaways/KeyTakeaways"
import CompanyProfile from "./Subcomponents/CompanyProfile/CompanyProfile"
import KnowMore from "./Subcomponents/KnowMore/KnowMore"
import OtherCaseStudies from "./Subcomponents/OtherCaseStudies/OtherCaseStudies"

import { translate, KEYS } from "./translations"
import BodySection from "./Subcomponents/BodySection/BodySection"

const CaseStudy = props => {
  const { locale, defaultLocale } = props.pageContext
  const videoOption = _.get(props, "data.contentfulClient.videoOption", "core")

  const sections = [
    {
      name: "customersHistory",
      htmlId: "customer_story",
      title: translate(KEYS.SECTION_TITLE_CUSTOMERS_HISTORY, locale),
      hasBackground: false,
      content: _.get(props, "data.contentfulClient.customersHistory", {}),
      locale,
      defaultLocale
    },
    {
      name: "customersNeeds",
      htmlId: "customer_needs",
      title: translate(KEYS.SECTION_TITLE_CUSTOMERS_NEEDS, locale),
      hasBackground: true,
      content: _.get(props, "data.contentfulClient.customersNeeds", {}),
      locale,
      defaultLocale
    },
    {
      name: "solution",
      htmlId: "solution",
      title: translate(KEYS.SECTION_TITLE_SOLUTION, locale),
      hasBackground: false,
      content: _.get(props, "data.contentfulClient.solution", {}),
      youtubeVideoId:
        videoOption !== "header"
          ? _.get(props, "data.contentfulClient.youtubeVideoId", "")
          : "",
      previewImage:
        videoOption !== "header"
          ? _.get(props, "data.contentfulClient.thumbnail", {})
          : {},
      videoName:
        videoOption !== "header"
          ? _.get(props, "data.contentfulClient.videoName", "")
          : "",
      footer: _.get(props, "data.contentfulClient.solutionFooter", null),
      locale,
      defaultLocale
    },
    {
      name: "conclusion",
      htmlId: "conclusion",
      title: translate(KEYS.SECTION_TITLE_CONCLUSION, locale),
      hasBackground: true,
      content: _.get(props, "data.contentfulClient.conclusion", {}),
      locale,
      defaultLocale
    },
    {
      name: "nextSteps",
      htmlId: "next_steps",
      title: translate(KEYS.SECTION_TITLE_NEXT_STEPS, locale),
      hasBackground: false,
      content: _.get(props, "data.contentfulClient.nextSteps", {}),
      locale,
      defaultLocale
    }
  ]

  const renderIntroSection = () => {
    if (videoOption === "header") {
      return <IntroVideo {...props} />
    } else {
      return <Intro {...props}></Intro>
    }
  }

  return (
    <div className={styles.caseStudy}>
      {renderIntroSection()}
      <KeyTakeaways {...props}></KeyTakeaways>
      <Container>
        <Row>
          <Col xs="12" lg="4" className={styles.moduleMargins}>
            <CompanyProfile {...props} />
          </Col>
          <Col xs="12" lg="8" className={styles.moduleMargins}>
            {sections.map(section => {
              return <BodySection key={section.name} {...section} />
            })}
            <hr></hr>
            <KnowMore {...props} />
          </Col>
        </Row>
      </Container>
      <OtherCaseStudies {...props} />
    </div>
  )
}

CaseStudy.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.object,
  pageContext: PropTypes.object
}
export default CaseStudy
