import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import MarkdownTitle from "../../../MarkdownTitle"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import styles from "./OtherCaseStudies.module.scss"
import Link from "../../../Link/Link"
import { translate, KEYS } from "../../translations"
const OtherCaseStudies = props => {
  const content = _.get(props, "data.contentfulClient.otherCaseStudies", null)
  const resources = _.get(content, "resources", [])
  const listItems = [
    { name: "activityArea", titleKey: KEYS.RESOURCE_BUSINESS_SECTOR },
    { name: "location", titleKey: KEYS.RESOURCE_LOCATION },
    { name: "problems", titleKey: KEYS.RESOURCE_PROBLEMS }
  ]
  const { locale, defaultLocale } = props.pageContext
  if (!content) return null

  const { headContent } = content
  const renderTitle = () => {
    if (!headContent) return null
    return (
      <div className={styles.sectionTitle}>
        <MarkdownTitle text={headContent.childMarkdownRemark.rawMarkdownBody} />
      </div>
    )
  }

  const renderListItem = (titleKey, list) => {
    return (
      <li>
        <p>{translate(titleKey, locale)}</p>
        <p>
          {list.map(
            (item, index) => `${item}${index === list.length - 1 ? "" : ", "}`
          )}
        </p>
      </li>
    )
  }

  const renderList = resource => {
    return (
      <ul className={styles.list}>
        {listItems.map(item => {
          const content = _.get(resource, item.name, [])
          if (!Array.isArray(content) || !content.length) return null
          return renderListItem(item.titleKey, content)
        })}
      </ul>
    )
  }

  const renderResource = resource => {
    return (
      <div className={styles.resource}>
        {resource.thumbnail && (
          <div className={styles.thumbnail}>
            <GatsbyImage
              image={resource.thumbnail.gatsbyImageData}
              alt={resource.thumbnail.description || ""}
            />
          </div>
        )}
        {!!resource.title && <h4 className={styles.title}>{resource.title}</h4>}
        {renderList(resource)}
        {resource.link && (
          <div className={styles.linkContainer}>
            <Link
              link={resource.link}
              locale={locale}
              defaultLocale={defaultLocale}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <Container className={styles.otherCaseStudies} id="case_studies">
      <Row>
        <Col>{renderTitle()}</Col>
      </Row>
      <Row className={styles.resourcesRow}>
        {resources.map(resource => {
          return (
            <Col
              xs="12"
              lg="4"
              key={resource.id}
              className={styles.resourceWrap}
            >
              {renderResource(resource)}
            </Col>
          )
        })}
      </Row>
      {content.link && (
        <Row>
          <Col>
            <Link
              link={content.link}
              locale={locale}
              defaultLocale={defaultLocale}
            />
          </Col>
        </Row>
      )}
    </Container>
  )
}
OtherCaseStudies.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}
export default OtherCaseStudies
