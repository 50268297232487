import cookie from "react-cookies"
import { getLocaleLanguageCode } from "../utilities/utilities"
import { trackEvent } from "./trackEvent"

const defaultOnFormSubmitEvent = "formSubmission"

const createUniqueOrderId = length => {
  let text = ""
  const possible = "1234567890"

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

export const useFormSubmissionTracking = () => {
  const isLeadGenForm = formName => {
    const isQuoteForm = formName === "Quote"
    const isDemoForm = formName === "Demo Request"
    const isDemoVideoForm = formName === "Demo video watch"
    const isTrialForm = formName === "Trial Submission"

    return isQuoteForm || isDemoForm || isDemoVideoForm || isTrialForm
  }

  const formFieldMap = {
    tag_form_type: "form_type_analytics",
    firstname: "firstName",
    lastname: "lastName",
    job_title: "title",
    tag_country: "country"
  }

  const arrayToString = value => {
    if (Array.isArray(value)) {
      return value.join(",")
    } else {
      return value
    }
  }

  const trackFormSubmission = ({
    formId: propFormId,
    formData,
    eventLabel: propEventLabel,
    eventAction: propEventAction,
    locale
  }) => {
    const languageCode = getLocaleLanguageCode(locale)
    const identityTraitMap = formFieldMap

    const identityTraits = {}

    if (formData) {
      formData.forEach(item => {
        if (identityTraitMap[item.name]) {
          identityTraits[identityTraitMap[item.name]] = arrayToString(
            item.value
          )
        } else {
          identityTraits[item.name] = arrayToString(item.value)
        }
      })
    }

    identityTraits.user_type = `${languageCode}-deal`

    // add cookie data
    const cookieKeys = ["fbc", "fbp", "gclid"]
    cookieKeys.forEach(key => {
      identityTraits[key] = cookie.load(key)
    })

    // send identiy event
    window.analytics.identify(identityTraits)

    let finalEventLabel = propEventLabel
    const formName = identityTraits[identityTraitMap.tag_form_type]

    if (!finalEventLabel) {
      if (formName) {
        finalEventLabel = `${propFormId}-${formName}`
      } else {
        finalEventLabel = `${propFormId}-hs_form`
      }
    }

    const trackData = { ...identityTraits }
    delete trackData.user_type
    trackData.category = "form"
    trackData.label = finalEventLabel
    trackData.hs_form_guid = propFormId

    let finalEventAction = defaultOnFormSubmitEvent
    if (formName) {
      finalEventAction = `${defaultOnFormSubmitEvent}__${formName}`
    }

    if (isLeadGenForm(formName)) {
      trackData.order_id = createUniqueOrderId(5)
    }

    trackEvent(finalEventAction, null, null, trackData)

    // send additional track request if eventAction is found in props or if the form is a lead gen form
    if (isLeadGenForm(formName)) {
      trackEvent("LeadGen Form Submission", null, null, trackData)
    } else if (propEventAction) {
      trackEvent(propEventAction, null, null, trackData)
    }
  }

  return { trackFormSubmission }
}
