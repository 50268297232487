import React from "react"
import PropTypes from "prop-types"
import styles from "./FeatureItemCard.module.scss"
import { isSVG } from "../../../../utilities/mediaUtils"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const FeatureItemCard = props => {
  const { title, titleColor, richTextBody, style, featuresIcon } = props

  const renderTitle = () => {
    if (!title) return null
    const titleClasses = [styles.title]
    if (style === "KeyTakeaways") {
      titleClasses.push(styles.bigFont)
    }

    let inlineStyle = {}
    if (titleColor) {
      inlineStyle = { color: `${titleColor}` }
    }

    return (
      <h2 className={titleClasses.join(" ")} style={inlineStyle}>
        {title}
      </h2>
    )
  }

  const renderMedia = media => {
    if (!media || !media?.file) {
      return
    }

    const classes = [styles.imageWrap]
    if (style === "KeyTakeaways") {
      classes.push(styles.smallMargin)
    }

    // SVG image
    if (isSVG(media)) {
      return (
        <div className={classes.join(" ")}>
          <img
            className={styles.svgImage}
            src={`${media.file.url}`}
            alt={media?.title}
          />
        </div>
      )
    }

    return (
      <div className={classes.join(" ")}>
        <GatsbyImage image={getImage(media)} alt={media?.title} />
      </div>
    )
  }

  const renderBody = () => {
    if (richTextBody && richTextBody?.raw) {
      return <div className={styles.body}>{renderRichText(richTextBody)}</div>
    }
    return null
  }

  return (
    <div className={styles.featureItemCard}>
      {renderTitle()}
      {renderMedia(featuresIcon)}
      {renderBody()}
    </div>
  )
}
FeatureItemCard.defaultProps = {
  style: "Default"
}
FeatureItemCard.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  richTextBody: PropTypes.object,
  featuresIcon: PropTypes.object,
  style: PropTypes.string.isRequired
}
export default FeatureItemCard
