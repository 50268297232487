const _ = require("lodash")
const { getLocaleLanguageCode } = require("../../utilities/utilities")

const Keys = {
  COMPANY_PROFILE_INDUSTRY: "COMPANY_PROFILE_INDUSTRY",
  COMPANY_PROFILE_LOCATION: "COMPANY_PROFILE_LOCATION",
  COMPANY_PROFILE_SIZE: "COMPANY_PROFILE_SIZE",
  COMPANY_PROFILE_USE_CASE: "COMPANY_PROFILE_USE_CASE",
  SECTION_TITLE_KEY_TAKEAWAYS: "SECTION_TITLE_KEYTAKEAWAYS",
  SECTION_TITLE_CUSTOMERS_HISTORY: "SECTION_TITLE_CUSTOMERS_HISTORY",
  SECTION_TITLE_CUSTOMERS_NEEDS: "SECTION_TITLE_CUSTOMERS_NEEDS",
  SECTION_TITLE_SOLUTION: "SECTION_TITLE_SOLUTION",
  SECTION_TITLE_CONCLUSION: "SECTION_TITLE_CONCLUSION",
  SECTION_TITLE_NEXT_STEPS: "SECTION_TITLE_NEXT_STEPS",
  SECTION_TITLE_KNOW_MORE: "SECTION_TITLE_KNOW_MORE",
  RESOURCE_BUSINESS_SECTOR: "RESOURCE_BUSINESS_SECTOR",
  RESOURCE_LOCATION: "RESOURCE_LOCATION",
  RESOURCE_PROBLEMS: "RESOURCE_PROBLEMS"
}

const translations = {
  fr: {
    [Keys.COMPANY_PROFILE_INDUSTRY]: "Secteur d’activité :",
    [Keys.COMPANY_PROFILE_LOCATION]: "Où :",
    [Keys.COMPANY_PROFILE_SIZE]: "Taille :",
    [Keys.COMPANY_PROFILE_USE_CASE]: "Enjeu :",
    [Keys.SECTION_TITLE_KEY_TAKEAWAYS]: "Informations clés",
    [Keys.SECTION_TITLE_CUSTOMERS_HISTORY]: "Histoire du client",
    [Keys.SECTION_TITLE_CUSTOMERS_NEEDS]: "Besoins du client",
    [Keys.SECTION_TITLE_SOLUTION]: "Solution",
    [Keys.SECTION_TITLE_CONCLUSION]: "Conclusion",
    [Keys.SECTION_TITLE_NEXT_STEPS]: "Prochaines étapes",
    [Keys.SECTION_TITLE_KNOW_MORE]: "En savoir plus sur Agicap",
    [Keys.RESOURCE_BUSINESS_SECTOR]: "Secteur d’activité :",
    [Keys.RESOURCE_LOCATION]: "Où :",
    [Keys.RESOURCE_PROBLEMS]: "Enjeu :"
  },
  de: {
    [Keys.COMPANY_PROFILE_INDUSTRY]: "Industrie:",
    [Keys.COMPANY_PROFILE_LOCATION]: "Standort:",
    [Keys.COMPANY_PROFILE_SIZE]: "Größe:",
    [Keys.COMPANY_PROFILE_USE_CASE]: "Anwendungsfälle:",
    [Keys.SECTION_TITLE_KEY_TAKEAWAYS]: "Key Takeaways",
    [Keys.SECTION_TITLE_CUSTOMERS_HISTORY]: "Kundengeschichte",
    [Keys.SECTION_TITLE_CUSTOMERS_NEEDS]: "Kundenwünsche",
    [Keys.SECTION_TITLE_SOLUTION]: "Lösung",
    [Keys.SECTION_TITLE_CONCLUSION]: "Fazit",
    [Keys.SECTION_TITLE_NEXT_STEPS]: "Die nächsten Schritte",
    [Keys.SECTION_TITLE_KNOW_MORE]: "Erfahren Sie mehr über uns",
    [Keys.RESOURCE_BUSINESS_SECTOR]: "Wirtschaftssektor:",
    [Keys.RESOURCE_LOCATION]: "Wo:",
    [Keys.RESOURCE_PROBLEMS]: "Cashflow-Problem:"
  },
  es: {
    [Keys.COMPANY_PROFILE_INDUSTRY]: "Sector de actividad:",
    [Keys.COMPANY_PROFILE_LOCATION]: "Dónde:",
    [Keys.COMPANY_PROFILE_SIZE]: "Tamaño:",
    [Keys.COMPANY_PROFILE_USE_CASE]: "Retos:",
    [Keys.SECTION_TITLE_KEY_TAKEAWAYS]: "Información esencial",
    [Keys.SECTION_TITLE_CUSTOMERS_HISTORY]: "Historia del cliente",
    [Keys.SECTION_TITLE_CUSTOMERS_NEEDS]: "Necesidades del cliente",
    [Keys.SECTION_TITLE_SOLUTION]: "Solución",
    [Keys.SECTION_TITLE_CONCLUSION]: "Conclusión",
    [Keys.SECTION_TITLE_NEXT_STEPS]: "Próximos pasos",
    [Keys.SECTION_TITLE_KNOW_MORE]: "Más información",
    [Keys.RESOURCE_BUSINESS_SECTOR]: "Sector de actividad:",
    [Keys.RESOURCE_LOCATION]: "Dónde:",
    [Keys.RESOURCE_PROBLEMS]: "Retos:"
  },
  en: {
    [Keys.COMPANY_PROFILE_INDUSTRY]: "Industry:",
    [Keys.COMPANY_PROFILE_LOCATION]: "Where:",
    [Keys.COMPANY_PROFILE_SIZE]: "Size:",
    [Keys.COMPANY_PROFILE_USE_CASE]: "Use cases:",
    [Keys.SECTION_TITLE_KEY_TAKEAWAYS]: "Key takeaways",
    [Keys.SECTION_TITLE_CUSTOMERS_HISTORY]: "Customer’s history",
    [Keys.SECTION_TITLE_CUSTOMERS_NEEDS]: "Customer’s needs",
    [Keys.SECTION_TITLE_SOLUTION]: "Solution",
    [Keys.SECTION_TITLE_CONCLUSION]: "Conclusion",
    [Keys.SECTION_TITLE_NEXT_STEPS]: "Next steps",
    [Keys.SECTION_TITLE_KNOW_MORE]: "Know more about us",
    [Keys.RESOURCE_BUSINESS_SECTOR]: "Business Sector:",
    [Keys.RESOURCE_LOCATION]: "Where:",
    [Keys.RESOURCE_PROBLEMS]: "Cashflow Problem:"
  },
  nl: {
    [Keys.COMPANY_PROFILE_INDUSTRY]: "Industry:",
    [Keys.COMPANY_PROFILE_LOCATION]: "Where:",
    [Keys.COMPANY_PROFILE_SIZE]: "Size:",
    [Keys.COMPANY_PROFILE_USE_CASE]: "Use cases:",
    [Keys.SECTION_TITLE_KEY_TAKEAWAYS]: "Key takeaways",
    [Keys.SECTION_TITLE_CUSTOMERS_HISTORY]: "Customer’s history",
    [Keys.SECTION_TITLE_CUSTOMERS_NEEDS]: "Customer’s needs",
    [Keys.SECTION_TITLE_SOLUTION]: "Solution",
    [Keys.SECTION_TITLE_CONCLUSION]: "Conclusion",
    [Keys.SECTION_TITLE_NEXT_STEPS]: "Next steps",
    [Keys.SECTION_TITLE_KNOW_MORE]: "Know more about us",
    [Keys.RESOURCE_BUSINESS_SECTOR]: "Business Sector:",
    [Keys.RESOURCE_LOCATION]: "Where:",
    [Keys.RESOURCE_PROBLEMS]: "Cashflow Problem:"
  },
  it: {
    [Keys.COMPANY_PROFILE_INDUSTRY]: "Settore di attività:",
    [Keys.COMPANY_PROFILE_LOCATION]: "Dove:",
    [Keys.COMPANY_PROFILE_SIZE]: "Dimensione:",
    [Keys.COMPANY_PROFILE_USE_CASE]: "Use case:",
    [Keys.SECTION_TITLE_KEY_TAKEAWAYS]: "Punti chiave",
    [Keys.SECTION_TITLE_CUSTOMERS_HISTORY]: "Storia del cliente",
    [Keys.SECTION_TITLE_CUSTOMERS_NEEDS]: "Esigenze del cliente",
    [Keys.SECTION_TITLE_SOLUTION]: "Soluzione",
    [Keys.SECTION_TITLE_CONCLUSION]: "Conclusioni",
    [Keys.SECTION_TITLE_NEXT_STEPS]: "Prossimi passi",
    [Keys.SECTION_TITLE_KNOW_MORE]: "Per saperne di più",
    [Keys.RESOURCE_BUSINESS_SECTOR]: "Settore di attività:",
    [Keys.RESOURCE_LOCATION]: "Dove:",
    [Keys.RESOURCE_PROBLEMS]: "Problema di cashflow:"
  },
  da: {
    [Keys.COMPANY_PROFILE_INDUSTRY]: "Industry:",
    [Keys.COMPANY_PROFILE_LOCATION]: "Where:",
    [Keys.COMPANY_PROFILE_SIZE]: "Size:",
    [Keys.COMPANY_PROFILE_USE_CASE]: "Use cases:",
    [Keys.SECTION_TITLE_KEY_TAKEAWAYS]: "Key takeaways",
    [Keys.SECTION_TITLE_CUSTOMERS_HISTORY]: "Customer’s history",
    [Keys.SECTION_TITLE_CUSTOMERS_NEEDS]: "Customer’s needs",
    [Keys.SECTION_TITLE_SOLUTION]: "Solution",
    [Keys.SECTION_TITLE_CONCLUSION]: "Conclusion",
    [Keys.SECTION_TITLE_NEXT_STEPS]: "Next steps",
    [Keys.SECTION_TITLE_KNOW_MORE]: "Know more about us",
    [Keys.RESOURCE_BUSINESS_SECTOR]: "Business Sector:",
    [Keys.RESOURCE_LOCATION]: "Where:",
    [Keys.RESOURCE_PROBLEMS]: "Cashflow Problem:"
  }
}

module.exports.KEYS = Keys

module.exports.translate = function (key, locale) {
  const languageCode = getLocaleLanguageCode(locale)
  return _.get(translations, [languageCode, key], "")
}
